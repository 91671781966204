<template>
    <div v-if="tableData">
        <el-card class="management-report-card-box">
            <el-form v-loading="$waiting.is('loading-profitDisposition')">
                <div class="flex flex-row justify-between items-center mb-2">
                    <h2 class="text-base font-semibold mb-2">{{ title }}</h2>
                    <div class="flex items-center">
                        <el-tooltip v-show="!tableData.isProfitDistributionsOriginal" placement="top">
                            <el-tag type="danger">Det har skett ändringar i bokföringen</el-tag>
                            <div slot="content" class="tooltip-content">
                                <p>Korrekta summor har inte beräknats, uppdatera för att hämta nya summor.</p>
                            </div>
                        </el-tooltip>
                        <RefreshButton
                            :card-title="title"
                            :annual-report-id="annualReportId"
                            :refresh-data="refreshData"
                            loading-key="loading-profitDisposition"
                            :success-message="`Hämtat ny data till flerårsöversikt`"
                            :disable-button="showEditButton"
                        />
                        <InfoButton :content="infoContent" />
                        <el-switch v-model="localToggleValue" active-color="#48c78e" inactive-color="#D1D5DB" @change="onToggleChange" />
                    </div>
                </div>
                <div v-if="toggleValue">
                    <div>
                        <el-form-item>
                            <div class="flex flex-col">
                                <el-table :data="tableData.profitDistributions" style="width: 100%" :show-header="false" class="text-sm">
                                    <el-table-column width="600px">
                                        <template slot-scope="scope">
                                            <el-input v-if="scope.row.input && scope.row.showInput" v-model="scope.row.leftValue" style="width: 300px;" :disabled="inputDisabled" />
                                            <div v-else>
                                                <span v-html="formatText(scope.row.profitDistributionTitle)" />
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column>
                                        <template slot-scope="scope">
                                            <el-input
                                                v-if="scope.row.profitDistributionTitle != 'disponeras så att'"
                                                v-model.number="scope.row.rightValue"
                                                @change="
                                                    calculateData();
                                                    calculateDataNumberTwo();
                                                "
                                                :disabled="inputDisabled || isAlwaysDisabled(scope.row.profitDistributionTitle)"
                                            />
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                            <el-button size="mini" type="warning" @click="toggleInputs" class="mt-2">
                                {{ showInput1 ? "Ta bort extraraderna" : "Lägg till fler rader" }}
                            </el-button>
                        </el-form-item>
                    </div>
                    <div class="flex flex-col mt-4 text-sm">
                        <div style="margin: 30px 0px 10px 0px;">
                            <p>Till aktieägare utdelas {{ calculateShares() }} kronor per aktie</p>
                        </div>
                        <el-checkbox :disabled="inputDisabled" v-model="tableData.boardAuthorizedToSetPaymentDate" style="margin-bottom: 10px;">
                            Styrelsen bemyndigas att fastställa betalningsdag
                        </el-checkbox>
                        <div>
                            <el-checkbox :disabled="inputDisabled" v-model="tableData.setPaymentDay">Utdelningen skall senast betalas till aktieägarna senast</el-checkbox>
                            <el-date-picker :disabled="inputDisabled" v-model="tableData.setPaymentDayDate" style="width:150px; margin-left: 10px;" />
                        </div>
                    </div>
                    <div v-show="localShowComment">
                        <h2 class="text-base font-semibold mb-2 mt-5">Yttrande från styrelsen:</h2>
                        <div v-if="inputText">
                            <el-input type="textarea" :disabled="inputDisabled" v-model="localInputText" rows="4" class="break-normal custom-textarea" />
                        </div>
                    </div>
                </div>
            </el-form>
            <CardEditButtons
                :toggle-value="toggleValue"
                :show-edit-button="showEditButton"
                :toggle-edit="toggleEdit"
                :close-modal="closeModal"
                :save-data="saveData"
                :show-comment-button="true"
                :show-comment="localShowComment"
                @toggle-comment="handleToggleComment"
            />
        </el-card>
    </div>
</template>
<script>
import { Switch } from "element-ui";
import Api from "../annualReport.api";
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
        inputText: {
            type: String,
            default: "",
        },
        tableData: {
            type: Object,
            default: () => {},
        },
        toggleValue: {
            type: Boolean,
            default: true,
        },
        showComment: {
            type: Boolean,
        },
        annualReportId: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            updateTableData: [],
            selectedYear: null,
            financialYearId: null,
            input1: "",
            input2: "",
            showInput1: false,
            inputDisabled: true,
            showEditButton: true,
            localToggleValue: this.toggleValue,
            localInputText: this.inputText,
            showCommentButton: true,
            infoContent: `Aktiebolag och ekonomiska föreningar ska i förvaltningsberättelsen lämna förslag till dispositioner beträffande bolagets eller föreningens vinst eller förlust.<br /><br /> ÅRL 6 kap. 2 § första stycket.`,
            newIncomeStatementAndProfitDistributions: [],
            localShowComment: this.showComment,
        };
    },
    watch: {
        inputText(newVal) {
            this.localInputText = newVal;
        },
        localInputText(newVal) {
            this.$emit("update:text", newVal);
        },
        toggleValue(newVal) {
            this.localToggleValue = newVal;
        },
    },
    components: {
        "el-switch": Switch,
        CardEditButtons: () => import("./components/CardEditButtons.vue"),
        InfoButton: () => import("./components/InfoButton.vue"),
        RefreshButton: () => import("./components/RefreshData.vue"),
    },
    methods: {
        async refreshData() {
            const refreshProfitDisposition = await Api.getManagementReportBooking(this.annualReportId, "profitDistribution");
            this.newIncomeStatementAndProfitDistributions = refreshProfitDisposition;
            this.$emit("update:profitDistributions", this.newIncomeStatementAndProfitDistributions);
        },
        handleToggleComment(newShowComment) {
            this.localShowComment = newShowComment;
        },
        isAlwaysDisabled(title) {
            const alwaysDisabledTitles = ["summa", "överföres i ny räkning", "disponeras så att"];
            return alwaysDisabledTitles.includes(title);
        },
        toggleEdit() {
            this.inputDisabled = !this.inputDisabled;
            this.showEditButton = !this.showEditButton;
        },
        toggleComment() {
            this.showComment = !this.showComment;
        },
        onToggleChange(newVal) {
            const eventData = {
                source: 7,
                value: newVal,
            };
            this.$emit("toggle-change", eventData);
        },
        async saveData() {
            this.updateTableData = this.tableData;
            this.updateTableData.showStatementFromBoard = this.localShowComment;
            this.$emit("update-table-data", this.updateTableData);
            this.$emit("create");
            this.$notify.success({ title: 'Sparat "Resultatdisposition"' });
            this.toggleEdit();
        },
        formatText(text) {
            if (text === "summa") {
                return "<b>Summa</b>";
            }
            return text;
        },
        toggleInputs() {
            this.showInput1 = !this.showInput1;

            const newRow1 = { input: true, showInput: true, leftValue: "", rightValue: "" };
            const newRow2 = { input: true, showInput: true, leftValue: "", rightValue: "" };
            const newRow3 = { input: true, showInput: true, leftValue: "", rightValue: "" };
            const newRow4 = { input: true, showInput: true, leftValue: "", rightValue: "" };

            if (this.showInput1) {
                this.tableData.profitDistributions.splice(2, 0, newRow1, newRow2);
                this.tableData.profitDistributions.splice(8, 0, newRow3, newRow4);
            } else {
                this.tableData.profitDistributions.splice(2, 2);
                this.tableData.profitDistributions.splice(6, 2);
                this.calculateData();
            }
        },
        calculateData() {
            let sum = 0;
            let lastRowIndex = -1;
            for (let i = 0; i < this.tableData.profitDistributions.length; i++) {
                if (this.tableData.profitDistributions[i].profitDistributionTitle === "summa") {
                    this.tableData.profitDistributions[i].rightValue = sum.toFixed(0);
                    lastRowIndex = i;
                    break;
                }
                sum += parseFloat(this.tableData.profitDistributions[i].rightValue || 0);
            }
            if (lastRowIndex !== -1 && lastRowIndex < this.tableData.profitDistributions.length - 1) {
                this.tableData.profitDistributions[this.tableData.profitDistributions.length - 1].rightValue = this.tableData.profitDistributions[lastRowIndex].rightValue;
            }
        },
        calculateDataNumberTwo() {
            let sum = 0;
            for (let i = 0; i < this.tableData.profitDistributions.length; i++) {
                if (this.tableData.profitDistributions[i].profitDistributionTitle === "summa") {
                    continue;
                }
                if (this.tableData.profitDistributions[i].profitDistributionTitle === "överföres i ny räkning") {
                    const shareAmounts = this.tableData.profitDistributions.find(item => item.profitDistributionTitle === "till aktieägare utdelas");
                    this.tableData.profitDistributions[i].rightValue = sum.toFixed(0) - shareAmounts.rightValue * 2;
                    break;
                }
                sum += parseFloat(this.tableData.profitDistributions[i].rightValue || 0);
            }
        },
        calculateShares() {
            const shareAmounts = this.tableData.profitDistributions.find(item => item.profitDistributionTitle === "till aktieägare utdelas").rightValue;
            this.tableData.shareholderDividend = shareAmounts ? (shareAmounts / this.tableData.numberOfShares).toFixed(0) : 0;
            return this.tableData.shareholderDividend;
        },
        closeModal() {
            this.$emit("close");
            this.toggleEdit();
        },
    },
};
</script>
